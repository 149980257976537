

/* JQUERY FUNCTION */

function delaySpan(el, delay) {
  el.each(function () {
    $(this).children().children().each(function (a) {
      a++;
      setCssOrderDelay($(this), a, delay);
    })
  });
}

function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight
  var newHeight;

  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) callback();
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 200);
  })();
}

function cssSetUnderline(element, left, width) {
  return element.css({ "left": left + "px", "width": width + "px" });
}
function setRandomDelay(number, range) {
  return (Math.random()) * number * range + 'ms';
}
function setOrderDelay(number, range) {
  return number * range + 'ms';
}
function setCssRandomDelay(element, indexElement, range) {
  element.css({
    "-webkit-transition-delay": setRandomDelay(indexElement, range),
    "transition-delay": setRandomDelay(indexElement, range)
  });
}
function setCssOrderDelay(element, indexElement, range) {
  element.css({
    "-webkit-transition-delay": setOrderDelay(indexElement, range),
    "transition-delay": setOrderDelay(indexElement, range)
  });
}
function onHoverAddClass(hoverElment, element, classToAdd) {
  hoverElment.hover(function () { element.addClass(classToAdd); }, function () { element.removeClass(classToAdd); })
}

/**
 * Раскрывает выпадающий блок
 * @param  {Object} path             Путь до елемента с которого всё начинается (стрелка)
 * @param  {String} parent_name      Класс родителя откуда от места начала (".exampleClass")
 * @param  {String} children_in      Класс скрытого блока (".exampleClass")
 * @param  {String | Number} duration_h  Время исполнения анимации (slow|normal|fast)
 * @param  {Boolean} hidden_path     Скрывать path элемент (true) или нет (false)
 * @param  {Number} a_time           Задаёт задержку раскрытия в милисекундах
 * @return {undefined}
 */
function wiyzjalka(path, parent_name, children_in, duration_h, hidden_path, a_time) {
  var a_time = (!a_time) ? 0 : a_time;
  path.each(function () {
    if ($(this).parents(parent_name).find(children_in).find(".js-active").length > 0 || $(this).hasClass('js-open')) {
      $(this).parents(parent_name).addClass("js-open");
      $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
      if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
        $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
      } else {
        $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
      }
    }
    $(this).bind("click", function () {
      if ($(children_in).find('.hide-box').length) {
        var dMSec = (duration_h == "slow") ? 600
          : (duration_h == "normal") ? 400
            : (duration_h == "fast") ? 200 : 0
        setTimeout(function () {
          toolboxSetClass($('.hide-box'));
        }, dMSec + a_time);
      }
      if (hidden_path) {
        $(this).hide();
      }
      path.parents(parent_name).find(children_in).stop(true, false).slideUp(duration_h);
      path.parents(parent_name).removeClass("js-open");
      path.parents(parent_name).removeClass("js-active");
      if ($(this).parents(parent_name).find(children_in).is(children_in)) {
        if ($(this).parents(parent_name).find(children_in).is(":visible")) {
          $(this).parents(parent_name).removeClass("js-open");
          $(this).parents(parent_name).find(children_in).stop().slideUp(duration_h, function () {
            // console.log($(this).css());
            $(this).css("height", "auto");
          });
          if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
          } else {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
          }
        } else {
          if ($(this).parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
          } else {
            $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
          }
          $(this).parents(parent_name).addClass("js-open");
          $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
        };
      };
    });
  });
};

function setFileNameInput(element) {
  var fileInput = element.find('input[type="file"]');
  fileInput.change(function () {
    element.find('input[type="text"]').val('"' + $(this).val().slice($(this).val().lastIndexOf("\\") + 1) + '"');
  });
}


function owlA11ySet(a, selector, autoTime) {

  // console.log(a);

  var owlID = a.attr('id');

  a.on("initialized.owl.carousel", function (e) {
    var owlCarouselItem = $('#' + owlID + ' .owl-item');
    // add listbox id's to all items
    for (i = 0; i < owlCarouselItem.length; i++) {
      $('#' + owlID + ' .owl-item').eq(i).attr("id", owlID + '-' + (i + 1));
    }
    // add accesibility properties to all items
    owlItemMakeInactive(e, (selector) ? true : false);
    owlItemMakeActive(e, (selector) ? true : false);

    setTimeout(function () {
      // console.log($('#'+owlID).find('.owl-dots'));
      $('#' + owlID).children('.owl-dots').children().each(function (a) {
        $(this).children().addClass('visually-hidden').text(owlID + " - " + a)
        // console.log($(this));
      })
    }, 0);

  });

  // after the carousel's been changed, adjust items' attributes
  a.on("translate.owl.carousel", function (e) {
    setTimeout(function () {
      owlItemMakeInactive(e, (selector) ? true : false);
      owlItemMakeActive(e, (selector) ? true : false);
    }, 100);
  });

  // add keyboard navigation to the owl carousel
  a.on('keyup', function (event) {
    // handle cursor keys
    if (event.keyCode == 37) {
      // go left
      a.trigger('prev.owl.carousel');
    } else if (event.keyCode == 39) {
      // go right
      a.trigger('next.owl.carousel');
    }
  });

  if (autoTime) {
    a.on('focusout', function () {
      a.trigger('play.owl.autoplay', [autoTime])
    })
    a.on('focusin', function () {
      a.trigger('stop.owl.autoplay')
    })
  }

};

function setAttributes(el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

/* Changing owl item attributes
 * set the item itself and its children to be non-tabable when inactive
 * reverse when activated
 */
function owlItemMakeInactive(e, owlActiveEl) {

  var selector = (owlActiveEl) ? '.center' : '.active',
    owlItem = e.currentTarget.querySelectorAll(".owl-item:not(" + selector + ")");
  for (var i = owlItem.length - 1; i >= 0; i--) {
    setAttributes(
      owlItem[i],
      {
        "role": "option",
        "aria-selected": "false"
      }
    );
    for (var j = owlItem[i].querySelectorAll('a').length - 1; j >= 0; j--) {
      owlItem[i].querySelectorAll('a')[j].setAttribute('tabindex', '-1');
    }
  }
}
function owlItemMakeActive(e, owlActiveEl) {

  var selector = (owlActiveEl) ? '.center' : '.active',
      owlItem = e.currentTarget.querySelectorAll(".owl-item" + selector);
  // console.log(owlActiveEl);
  for (var i = owlItem.length - 1; i >= 0; i--) {
    setAttributes(
      owlItem[i],
      {
        "role": "option",
        "aria-selected": "true"
      }
    );
    for (var j = owlItem[i].querySelectorAll('a').length - 1; j >= 0; j--) {
      owlItem[i].querySelectorAll('a')[j].setAttribute('tabindex', '0');
    }
  }
}

/* JQUERY END FUNCTION */


/*  JS FUNCTIONS  */

function getScrollbarWidth() {
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

function isInPage(node) {
  return (node === document.body) ? false : document.body.contains(node);
}

function removeActiveClassChild(parent,cls) {
  var cls = (cls) ? cls : 'active';
  if (parent.querySelector('.' + cls)) {
    parent.querySelector('.' + cls).classList.remove(cls)
  }
}

function hasClass(element, cls) {
  return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

function scrollToTop(scrollDuration) {
  var scrollStep = -window.scrollY / (scrollDuration / 15),
    scrollInterval = setInterval(function () {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
}

function tabWork(element, idBox, additClass) {
  for (var i = element.length - 1; i >= 0; i--) {
    var parent = element[0].parentNode.parentNode,
        a = 'active';
    element[i].addEventListener('click', function (e) {
      onElementHeightChange(document.body, function () {
        AOS.refresh();
      });
      removeActiveClassChild(parent);
      this.classList.add(a);
      var parentBox = document.getElementById(idBox),
        listID = parentBox.querySelector(this.getAttribute("data-id"));
      removeActiveClassChild(parentBox);
      if (additClass) {
        setChildHeightToParent(listID);
      }
      try {
        listID.classList.add(a);
      }
      catch (s) {
        console.log(s);
      }
      if (additClass && typeof (additClass) === "object") {
        setTimeLineActive(additClass, '.' + a);
      }
      e.preventDefault();
    });
  }
}

function setChildHeightToParent(children) {
  setTimeout(function () {
    children.parentNode.style.height = children.offsetHeight + "px";
  }, 100);
}
function setTimeLineActive(element, forActive) {
  var s = element.parentNode.querySelector(forActive);
  element.style.width = (s.offsetLeft + s.offsetWidth / 2) + "px";
}


/* Работа МЕНЮ */


function hideA11yElement(e) {
  if (e.hasAttribute('aria-hidden')) {
    if (e.getAttribute('aria-hidden') === "true") {
      e.setAttribute('aria-hidden', false);
    } else {
      e.setAttribute('aria-hidden', true);
      if (window.matchMedia('(min-width: 1000px)').matches) {
        e.style.display = 'none';
        setTimeout(function () {
          e.removeAttribute("style");
        }, 1000);
      }
    }
  }
}


function miniModal(el, bodyClassToggle) {
  // console.log(el);
  if (!el) {
    return false;
  }
  el.addEventListener('click', function (e) {
    var open = document.querySelectorAll('.open');
    for (var i = open.length - 1; i >= 0; i--) {
      if (!this.classList.contains('open')) {
        open[i].classList.remove('open');
        hideA11yElement(open[i]);
      }
    }
    document.body.classList.remove('b-modal-open');

    var href = this.dataset.id,
      box = (document.querySelector(href)) ? document.querySelector(href) : "#";
    if (!el.closest(href)) {
      box.classList.toggle('open');
      this.classList.toggle('open');

      hideA11yElement(box);

    }

    if (window.matchMedia('(min-width: 1000px)').matches) {
      setTimeout(function () {
        if (isInPage(box.querySelector("input"))) {
          // box.querySelector("input").focus();
        }
      }, 100);

      box.addEventListener("blur", function (e) {
        if (!e.relatedTarget || !box.contains(e.relatedTarget)) {
          setTimeout(function () {
            if (isInPage(box.querySelector("input"))) {
              // box.querySelector("input").focus();
            }
          }, 0);
        }
      }, true);
    }

    box.addEventListener('click', function (a) {
      a.stopPropagation();
    });
    if (isInPage(document.querySelector('.b-modal'))) {
      document.querySelector('.b-modal').addEventListener('click', function (a) {
        a.stopPropagation();
      });
    }

    if (!bodyClassToggle) {
      if (isInPage(document.querySelector('.open'))) {
        document.body.classList.add('b-modal-open');
      }
    }

    if (isInPage(box.querySelector('.js-focus-element'))) {
      setTimeout(function () {
        box.querySelector('.js-focus-element').focus();
        // console.log(box.querySelector('.js-focus-element'));
      }, 300);
    }

    e.stopPropagation();
    e.preventDefault();
  });

  var body = (!bodyClassToggle) ? '.b-modal-overlay' : 'body';

  document.querySelector(body).addEventListener('click', function (e) {
    var open = document.querySelectorAll('.open');
    for (var i = open.length - 1; i >= 0; i--) {
      open[i].classList.remove('open');
      hideA11yElement(open[i]);
    }
    document.body.classList.remove('b-modal-open');
  });

  document.addEventListener('keyup', function (e) {
    if (e.keyCode === 27) {
      var open = document.querySelectorAll('.open');
      for (var i = open.length - 1; i >= 0; i--) {
        open[i].classList.remove('open');
        hideA11yElement(open[i]);
      }
      document.body.classList.remove('b-modal-open');
    }
  });

}




if (window.matchMedia('(max-width: 1000px)').matches) {
  +function ($) {
    'use strict';

    if (!$.fn.carousel) {
      throw new Error("carousel-swipe required bootstrap carousel")
    }

    // CAROUSEL CLASS DEFINITION
    // =========================

    var CarouselSwipe = function (element) {
      this.$element = $(element)
      this.carousel = this.$element.data('bs.carousel')
      this.options = $.extend({}, CarouselSwipe.DEFAULTS, this.carousel.options)
      this.startX =
        this.startY =
        this.startTime =
        this.cycling =
        this.$active =
        this.$items =
        this.$next =
        this.$prev =
        this.dx = null

      this.$element
        .on('touchstart', $.proxy(this.touchstart, this))
        .on('touchmove', $.proxy(this.touchmove, this))
        .on('touchend', $.proxy(this.touchend, this))
    }

    CarouselSwipe.DEFAULTS = {
      swipe: 50 // percent per second
    }

    CarouselSwipe.prototype.touchstart = function (e) {
      if (!this.options.swipe) return;
      var touch = e.originalEvent.touches ? e.originalEvent.touches[0] : e
      this.dx = 0
      this.startX = touch.pageX
      this.startY = touch.pageY
      this.cycling = null
      this.width = this.$element.width()
      this.startTime = e.timeStamp;
    }

    CarouselSwipe.prototype.touchmove = function (e) {
      if (!this.options.swipe) return;
      var touch = e.originalEvent.touches ? e.originalEvent.touches[0] : e
      var dx = touch.pageX - this.startX
      var dy = touch.pageY - this.startY
      if (Math.abs(dx) < Math.abs(dy)) return; // vertical scroll

      if (this.cycling === null) {
        this.cycling = !!this.carousel.interval
        this.cycling && this.carousel.pause()
      }

      e.preventDefault();
      this.dx = dx / (this.width || 1) * 100
      this.swipe(this.dx)
    }

    CarouselSwipe.prototype.touchend = function (e) {
      if (!this.options.swipe) return;
      if (!this.$active) return; // nothing moved
      var all = $()
        .add(this.$active).add(this.$prev).add(this.$next)
        .carousel_transition(true)

      var dt = (e.timeStamp - this.startTime) / 1000
      var speed = Math.abs(this.dx / dt) // percent-per-second
      if (this.dx > 40 || (this.dx > 0 && speed > this.options.swipe)) {
        this.carousel.prev()
      } else if (this.dx < -40 || (this.dx < 0 && speed > this.options.swipe)) {
        this.carousel.next();
      } else {
        this.$active
          .one($.support.transition.end, function () {
            all.removeClass('prev next')
          })
          .emulateTransitionEnd(this.$active.css('transition-duration').slice(0, -1) * 1000)
      }

      all.css('transform', '')
      this.cycling && this.carousel.cycle()
      this.$active = null // reset the active element
      e.preventDefault();
    }

    CarouselSwipe.prototype.swipe = function (percent) {
      var $active = this.$active || this.getActive()
      if (percent < 0) {
        this.$prev
          .css('transform', 'translate3d(0,0,0)')
          .removeClass('prev')
          .carousel_transition(true)
        if (!this.$next.length || this.$next.hasClass('active')) return
        this.$next
          .carousel_transition(false)
          .addClass('next')
          .css('transform', 'translate3d(' + (percent + 100) + '%,0,0)')
      } else {
        this.$next
          .css('transform', '')
          .removeClass('next')
          .carousel_transition(true)
        if (!this.$prev.length || this.$prev.hasClass('active')) return
        this.$prev
          .carousel_transition(false)
          .addClass('prev')
          .css('transform', 'translate3d(' + (percent - 100) + '%,0,0)')
      }

      $active
        .carousel_transition(false)
        .css('transform', 'translate3d(' + percent + '%, 0, 0)')
    }

    CarouselSwipe.prototype.getActive = function () {
      this.$active = this.$element.find('.item.active')
      this.$items = this.$active.parent().children()

      this.$next = this.$active.next()
      if (!this.$next.length && this.options.wrap) {
        this.$next = this.$items.first();
      }

      this.$prev = this.$active.prev()
      if (!this.$prev.length && this.options.wrap) {
        this.$prev = this.$items.last();
      }

      return this.$active;
    }

    // CAROUSEL PLUGIN DEFINITION
    // ==========================

    var old = $.fn.carousel
    $.fn.carousel = function () {
      old.apply(this, arguments);
      return this.each(function () {
        var $this = $(this)
        var data = $this.data('bs.carousel.swipe')
        if (!data) $this.data('bs.carousel.swipe', new CarouselSwipe(this))
      })
    }

    $.extend($.fn.carousel, old);

    $.fn.carousel_transition = function (enable) {
      enable = enable ? '' : 'none';
      return this.each(function () {
        $(this)
          .css('-webkit-transition', enable)
          .css('transition', enable)
      })
    };

  } (jQuery);
}




/* END* Работа МЕНЮ */

/*  *END* JS FUNCTIONS  */


